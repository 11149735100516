<template>
    <div
        class="big-links"
        :class="{ 'big-links--double': column2.length > 0 }"
    >
        <div>
            <BigLink
                v-for="(link, index) in column1"
                :key="index"
                :title="link?.label"
                :link-entry="link?.linkedEntry?.[0]"
            />
        </div>
        <div v-if="column2.length > 0">
            <BigLink
                v-for="(link, index) in column2"
                :key="index"
                :title="link?.label"
                :link-entry="link?.linkedEntry?.[0]"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        column1() {
            return this.links.slice(0, 3);
        },
        column2() {
            return this.links.slice(3, 5);
        }
    },
};
</script>

<style lang="less" src="./BigLinks.less"></style>
