<template>
    <BaseSection width="page-narrow" class="section--button" data-padding="small">
        <a
            class="button button--primary"
            :href="assetRelation?.[0]?.url"
            :class="colorClass"
            rel="noopener noreferrer"
            target="_blank"
        >
            {{ label }}
        </a>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    assetRelation: {
        type: Array,
        required: true,
        default() {
            return [];
        }
    },
    label: {
        type: String,
        required: true,
        default: 'Download'
    },
    color: {
        type: String,
        default: 'primary'
    },
});

const colorClass = computed(() => {
    return `button--${props.color}`;
});
</script>

<style lang="less">
    .section--button {
        .button {
            .focus-ball-left();
        }
    }
</style>
