<template>
    <BaseSection data-width="bleed" class="section--featured-cards">
        <template v-if="title" #title>{{ title }}</template>
        <template v-if="description" #description>
            <div v-html="description" /> <!-- eslint-disable-line -->
        </template>
        <FeaturedCards :items="posts" />
    </BaseSection>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        posts: {
            type: Array,
            required: true,
            default: null
        }
    }
};
</script>
