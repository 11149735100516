<template>
    <BaseSection
        v-if="entry"
        width="page-narrow"
    >
        <BaseSpinner v-if="pagePending || categoryPending || subsidyCountPending" />
        <CategoryCards
            v-else
            :categories="subsidyCategories"
            :count="subsidyCountsPerCategory"
        />
    </BaseSection>
</template>

<script setup>
import { formatISO } from 'date-fns';

import getPage from '~/graphql/queries/getPage.graphql';
import getSubsidyCategories from '~/graphql/queries/getSubsidyCategories.graphql';
import getActiveSubsidiesInCategory from '~/graphql/queries/getActiveSubsidiesInCategory.graphql';

const mapObject = (o, c) => Object.fromEntries(Object.entries(o).map(c));

const subsidySubCategories = computed(() => {
    return subsidyCategories.value
        ?.flatMap(({ children }) => children)
        ?.map(({ id }) => id) ?? [];
});

const subsidyCountsPerCategory = computed(() => {
    const grouped = {};

    subsidyCounts.value?.forEach(({ id: subsidyId, subsidyCategoryRelation }) => {
        subsidyCategoryRelation?.forEach(({ id: categoryId }) => {
            if (grouped[categoryId]) {
                grouped[categoryId].push(subsidyId);
            } else {
                grouped[categoryId] = [subsidyId];
            }
        });
    });

    return mapObject(grouped, ([k, v]) => [k, v.length]);
});

const { variables: defaultVariables }  = useCraftGraphql();

const variablesOverview = computed(() => {
    const now = formatISO(Date.now(), { representation: 'date' });

    return {
        ...defaultVariables,
        uri: undefined, // We don't need the URI for this query
        section: 'subsidiesOverview',
        now: ['or', `>= ${now}`, null]
    };
});

const variablesCategory = computed(() => {
    return {
        ...defaultVariables,
        group: 'subsidyCategory',
        level: 1
    };
});

const variablesSubsidyCount = computed(() => {
    return {
        ...defaultVariables,
        categoryIds: subsidySubCategories.value
    };
});

const { data: pageData, pending: pagePending } = await useLazyAsyncQuery({
    query: getPage,
    variables: variablesOverview
});

const { data: categoryData, pending: categoryPending } = await useLazyAsyncQuery({
    query: getSubsidyCategories,
    variables: variablesCategory
});

const entry = computed(() => pageData.value?.entry);
const subsidyCategories = computed(() => {
    return categoryData.value?.categories?.filter((category) => category?.children?.length);
});

const { data: subsidyCountData, pending: subsidyCountPending } = await useLazyAsyncQuery({
    query: getActiveSubsidiesInCategory,
    variables: variablesSubsidyCount
});

const subsidyCounts = computed(() => subsidyCountData.value?.entries);
</script>
