<template>
    <NuxtLink
        v-if="item?.uri"
        ref="card"
        class="image-card"
        :class="{ 'image-card--visible': isVisible }"
        :to="localePath(`/${item?.uri}`)"
        :event="isVisible ? 'click' : ''"
        @focus.prevent.stop="onFocusOrClick"
        @mousedown="onFocusOrClick"
    >
        <ClientOnly v-if="item?.video">
            <LazyBaseVideo
                ref="video"
                :video="item?.video"
                :poster="item?.image[0]?.w380"
                class="image-card__image"
            />
        </ClientOnly>
        <ImageAsset
            v-else-if="item?.image?.length"
            :image="item?.image[0]"
            :loading="loading"
            :decoding="decoding"
            :fetch-priority="fetchPriority"
            alt=""
            class="image-card__image"
            :sizes="sizes"
            draggable="false"
        />
        <div
            class="image-card__info"
        >
            <div>{{ item?.title }}</div>
            <div>{{ item?.postTypes?.[0]?.title }}</div>
        </div>
    </NuxtLink>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default() {
                return {};
            }
        },
        sizes: {
            type: String,
            default: '100vw'
        },
        loading: {
            type: String,
            default: 'lazy',
            validator: value => ['lazy', 'eager'].includes(value)
        },
        decoding: {
            type: String,
            default: 'async',
            validator: value => ['sync', 'async', 'auto'].includes(value)
        },
        fetchPriority: {
            type: String,
            default: 'auto',
            validator: value => ['high', 'low', 'auto'].includes(value)
        },
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onFocusOrClick() {
            if (!this.isVisible) {
                this.$refs.card.$el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            }
        }
    }
};
</script>

<style lang="less" src="./ImageCard.less"></style>
