<template>
    <BaseSpinner v-if="pending" />
    <BaseSection
        v-else
        class="section--deadlines"
    >
        <h3 class="section--deadlines__title">{{ t('deadlines.upcoming_deadlines') }}</h3>
        <ul>
            <DeadlineCard
                v-for="(deadline, index) in deadlines"
                :key="index"
                :deadline="deadline"
                dark
            />
        </ul>
    </BaseSection>
</template>

<script setup>
import { formatISO } from 'date-fns';
import getSubsidyDeadlines from '~/graphql/queries/getSubsidyDeadlines.graphql';

const props = defineProps({
    singleSubsidyRelation: {
        type: Array,
        required: true,
        default: () => { return []; },
    }
});

const { singleSubsidyRelation } = toRefs(props);

const { t } = useI18n();

const now = formatISO(Date.now(), { representation: 'date' });

const id = computed(() => {
    return singleSubsidyRelation.value?.[0]?.id ?? null;
});

const { localeProperties } = useI18n();

const variables = computed(() => {
    return {
        site: localeProperties?.value?.craftSiteHandle,
        section: 'subsidies',
        id: id.value,
        now: ['or', `>= ${now}`, null]
    };
});

const { data, pending } = await useLazyAsyncQuery({
    query: getSubsidyDeadlines,
    variables
});

const entry = computed(() => {
    return data?.value?.entry ?? null;
});

const deadlines = computed(() => {
    return entry?.value?.periodsRelation?.map((deadline) => {
        return {
            title: entry?.value?.title,
            deadline: deadline?.end,
            phase: deadline?.phase
        };
    });
});
</script>

<style lang="less">
    .section--deadlines {
        .section--deadlines__title {
            .typography-h4-mob();
            .typography-h5-desk();
            margin-bottom: 1rem;
        }

        ul {
            .list-clean();
        }
    }
</style>
