<template>
    <NuxtLink
        :to="externalUrl ? externalUrl : absolutify(uri)"
        :target="externalUrl ? '_blank' : '_self'"
        class="help-card"
    >
        <h3 v-if="label" class="help-card__label">{{ label }}</h3>
        <p v-if="description || isLastUpdatedRelevant" class="help-card__description">
            <span v-if="description">{{ description }}</span>
            <span v-if="isLastUpdatedRelevant" class="help-card__description-updated-on">{{ $t('help_card.last_updated') }} <time :datetime="lightFormat(new Date(lastUpdated), 'yyyy-MM-dd')">{{ $d(parseISO(lastUpdated), 'dateLong').replace('.', '') }}</time></span>
        </p>
        <BaseIcon v-if="icon" :icon="icon" class="help-card__icon" />
    </NuxtLink>
</template>

<script setup>
import { parseISO, lightFormat } from 'date-fns';

defineProps({
    label: {
        type: String,
        required: true
    },
    description: {
        type: String,
        default: ''
    },
    externalUrl: {
        type: String,
        required: false,
        default: null
    },
    uri: {
        type: String,
        required: false,
        default: ''
    },
    icon: {
        type: String,
        required: true
    },
    isLastUpdatedRelevant: {
        type: Boolean,
        default: false
    },
    lastUpdated: {
        type: String,
        default: null,
    }
});

function absolutify(url) {
    if (url.startsWith('/')) {
        return url;
    }

    return `/${url}`;
}
</script>

<style src="./HelpCard.less" lang="less"></style>
