<template>
    <BaseSection data-width="bleed">
        <BaseSpinner v-if="pending" />
        <div
            v-else
            class="post-cards-basic"
        >
            <BaseCard
                v-for="(item, index) in entries"
                :key="index"
                :card-item="item"
            />

            <BigLink
                :title="t('post_cards.big_link.title')"
                :link-entry="{ uri: t('post_cards.big_link.uri')} "
            />
        </div>
    </BaseSection>
</template>

<script setup>
import { formatISO } from 'date-fns';
import getPages from '~/graphql/queries/getPages.graphql';

const { t } = useI18n();
const { variables: defaultVariables }  = useCraftGraphql();

const now = formatISO(Date.now(), { representation: 'date' });

const variables = computed(() => {
    return {
        ...defaultVariables,
        uri: null,
        section: 'posts',
        limit: 4,
        now: ['or', `>= ${now}`, null]
    };
});

const { data, pending } = await useLazyAsyncQuery({
    query: getPages,
    variables
});

const entries = computed(() => data.value?.entries ?? []);
</script>

<style lang="less" src="./PostCardsBasic.less" />
