<template>
    <div
        v-if="!marketingCookiesAccepted"
        class="content-blocker"
    >
        <h2 class="content-blocker__title">
            <slot name="title">
                {{ t('gdpr.title') }}
            </slot>
        </h2>
        <div class="content-blocker__description">
            <slot name="description">
                <p>
                    {{ t('gdpr.description') }}
                </p>
            </slot>
        </div>
        <BaseButton
            class="content-blocker__button base-button--secondary"
            @click="handleAccept"
        >
            <slot name="label">
                {{ t('gdpr.label') }}
            </slot>
        </BaseButton>
        <BaseIcon
            class="content-blocker__icon"
            icon="video"
            aria-hidden="true"
        />
    </div>
    <slot v-else />
</template>

<script setup>
import { useEventListener } from '@vueuse/core';
import JSON5 from 'json5';

const COOKIEBOT_COOKIENAME = 'CookieConsent';

const { t } = useI18n();

const consentCookie = useCookie(COOKIEBOT_COOKIENAME, {
    readonly: true,
    default: () => ({
        necessary: true,
        preferences: false,
        statistics: false,
        marketing: false
    }),
    decode(value) {
        try {
            return JSON5.parse(decodeURIComponent(value));
        } catch (e) {
            return {};
        }
    }
});

const marketingCookiesAccepted = computed(() => consentCookie.value?.marketing === true ?? false);

const handleAccept = async() => {
    emit('accept');

    useEventListener(window, 'CookiebotOnAccept', () => {
        refreshCookie(COOKIEBOT_COOKIENAME);
    });

    await window?.Cookiebot?.submitCustomConsent(
        consentCookie.value.preferences,
        consentCookie.value.statistics,
        true,
        false
    );
};

const emit = defineEmits(['accept']);
</script>

<style src="./ContentBlocker.less" lang="less" scoped />
