<template>
    <li
        v-if="deadline"
        class="deadline-card"
        :class="dark ? 'deadline-card--dark' : 'deadline-card--default'"
    >
        <Component
            :is="deadline?.uri ? NuxtLinkComponent : 'div'"
            :to="localePath(`/${deadline?.uri}`)"
        >
            <span
                v-if="deadline?.title"
                class="deadline-card__title"
            >
                {{ deadline?.title }}
            </span>
            <time
                v-if="deadline?.deadline"
                class="deadline-card__date"
                :datetime="deadline?.deadline"
            >
                {{ dutchDate }} • {{ dutchTime }}
            </time>
            <span
                v-if="deadline?.phase"
                class="deadline-card__phase"
            >
                {{ deadline?.phase }}
            </span>
        </Component>
    </li>
</template>

<script setup>
const NuxtLinkComponent = resolveComponent('NuxtLink');
const localePath = useLocalePath();
</script>

<script>
import { parseISO, formatISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import datefnsLocale from '~/mixins/datefns-locale';

export default {
    mixins: [datefnsLocale],
    props: {
        deadline: {
            type: Object,
            required: true,
            default() {
                return {};
            }
        },
        dark: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dutchDate() {
            return formatInTimeZone(this.deadline?.deadline, 'Europe/Amsterdam', 'dd MMMM yyyy', { locale: this.dateFnsLocale });
        },
        dutchTime() {
            return formatInTimeZone(this.deadline?.deadline, 'Europe/Amsterdam', 'HH:mm', { locale: this.dateFnsLocale });
        },
    },
    methods: {
        parseISO,
        formatISO
    }
};
</script>

<style lang="less" src="./DeadlineCard.less" />
