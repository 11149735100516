<template>
    <NuxtLink
        :to="localePath(`/${linkEntry?.uri}`) ?? localePath('/')"
        class="big-link"
    >
        <h2 class="big-link__title">{{ title }}</h2>
        <BaseIcon icon="arrow-right-circle" />
    </NuxtLink>
</template>

<script setup>
defineProps({
    title: {
        type: String,
        required: true
    },
    linkEntry: {
        type: Object,
        required: true
    }
});

const localePath = useLocalePath();
</script>

<style lang="less" src="./BigLink.less" />
