<template>
    <div
        ref="form"
        class="typeform-embed"
    />
</template>

<script setup>
import { createWidget } from '@typeform/embed';

const TYPEFORMREGEX = /[^/]*$/;

const props = defineProps({
    url: {
        type: String,
        required: true,
        default: ''
    },
});

const { url } = toRefs(props);

// template-refs
const form = ref(null);

const parseId = (url_) => {
    const matches = toValue(url_).match(TYPEFORMREGEX);
    return matches[0];
};

const id = computed(() => parseId(url));

onMounted(() => {
    createWidget(
        id.value,
        {
            container: form.value,
            opacity: 0,
            autoResize: '500,1000'
        }
    );
});
</script>

<style lang="css" src="@typeform/embed/build/css/widget.css" />
