export default {
    props: {
        sections: {
            type: Array,
            default() { return []; }
        },
        __typename: { // eslint-disable-line vue/prop-name-casing
            type: String,
            default: null
        }
    }
};
