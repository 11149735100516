<template>
    <BaseSection data-width="bleed">
        <div class="post-cards-basic">
            <BaseCard v-for="(post, index) in posts" :key="index" :card-item="post" />
            <BigLink :title="$t('post_cards.big_link.title')" :link-entry="{uri: $t('post_cards.big_link.uri')}" />
        </div>
    </BaseSection>
</template>

<script>
export default {
    props: {
        posts: {
            type: Array,
            required: true,
            default: null
        }
    }
};
</script>

<style lang="less" src="./PostCardsBasic.less"></style>
