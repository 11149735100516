<template>
    <nuxt-link
        :to="localePath(`/${category?.uri}`)"
        class="category-card"
    >
        <h3 class="category-card__title">{{ category?.title }}</h3>

        <i18n-t
            class="category-card__amount"
            keypath="subsidies.activeSubsidies"
            tag="p"
            :plural="count?.[category?.id] ?? 0"
        >
            <template v-slot:count>{{ count?.[category.id] }}</template>
        </i18n-t>

        <BaseIcon
            class="category-card__icon"
            icon="arrow-right"
        />
    </nuxt-link>
</template>

<script setup>
const localePath = useLocalePath();

defineProps({
    category: {
        type: Object,
        default: () => {}
    },
    count: {
        type: Object,
        default() {
            return {};
        }
    }
});
</script>

<style lang="less" src="./CategoryCard.less" />
