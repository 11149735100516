<template>
    <BaseSection
        data-width="bleed"
        class="section--slider-cards section--small-title"
    >
        <template
            v-if="title"
            #title
        >
            {{ title }}
        </template>
        <template
            v-if="description"
            #description
        >
            <div v-html="description" /> <!-- eslint-disable-line -->
        </template>
        <BaseSpinner v-if="pending" />
        <CardSlider
            v-else
            :items="data.entries"
        />
    </BaseSection>
</template>

<script setup>
import { formatISO } from 'date-fns';
import getPages from '~/graphql/queries/getPages.graphql';

defineProps({
    title: {
        type: String,
        default: ''
    },
    description: {
        type: String,
        default: ''
    }
});

const { variables: defaultVariables }  = useCraftGraphql();

const now = formatISO(Date.now(), { representation: 'date' });

const variables = computed(() => {
    return {
        ...defaultVariables,
        uri: null,
        section: 'posts',
        limit: 6,
        now: ['or', `>= ${now}`, null]
    };
});

const { data, pending } = await useLazyAsyncQuery({
    query: getPages,
    variables
});
</script>
