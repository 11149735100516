<template>
    <BaseSection width="page-narrow">
        <template v-if="title" #title>{{ title }}</template>
        <template v-if="description" #description>
            <div v-html="description" /> <!-- eslint-disable-line -->
        </template>
        <BaseGrid class="helpcards--grid" stretch>
            <HelpCard
                v-for="(card, i) in helpCards"
                :key="i"
                :label="card?.label"
                :description="card?.description"
                :icon="card?.icon"
                :uri="localePath(`/${card?.uri}`)"
                :external-url="card?.externalUrl"
                :is-last-updated-relevant="card?.isLastUpdatedRelevant"
                :last-updated="card?.dateUpdated"
            />
        </BaseGrid>
    </BaseSection>
</template>

<script>
import defaultFieldProps from '~/components/FlexibleSections/Blocks/defaultFieldProps';

export default {
    mixins: [
        defaultFieldProps
    ],
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        helpCards: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="less">
    .base-grid.helpcards--grid {
        grid-gap: var(--grid-gap-sm);

        @media @q-md-min {
            grid-gap: var(--grid-gap-sm);
        }

        @media @q-xxl-min {
            grid-gap: var(--grid-gap-md);
        }
    }
</style>
