<template>
    <div v-if="items && items.length > 0" class="featured-cards" :data-start-wide="items.length % 3 !== 2">
        <ImageCard
            v-for="(item, index) in items"
            :key="index"
            :item="item"
            class="featured-cards__card"
            :sizes="(items.length === 1 || (items.length === 3 && index === 1)) ? '100vw' : '(min-width: 768px) 50vw, 100vw'"
        />
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default() {
                return [];
            }
        }
    }
};
</script>

<style lang="less" src="./FeaturedCards.less"></style>
