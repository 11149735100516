<template>
    <DnAccordions
        :items="items"
        class="the-accordion"
        :scroll-offset="90"
        :component-id="uniqueId"
    >
        <template v-for="(_, name) in $slots" v-slot:[name]="data">
            <slot :name="name" v-bind="data" />
        </template>
    </DnAccordions>
</template>

<script setup>
import DnAccordions from '@digitalnatives/accordion';

const uniqueId = useId();

defineProps({
    large: {
        type: Boolean,
        required: false,
        default: false
    },
    items: {
        type: Array,
        required: true,
        default() { return []; }
    }
});
</script>

<style lang="less" src="./TheAccordion.less" />
