<template>
    <BaseSection
        v-if="subsidies"
        width="page-narrow"
        class="section--subsidies"
    >
        <SubsidyCard
            v-for="(subsidy, key) in subsidies"
            :key="key"
            :subsidy="subsidy"
        />
    </BaseSection>
</template>

<script>
import defaultFieldProps from '~/components/FlexibleSections/Blocks/defaultFieldProps';

export default {
    mixins: [defaultFieldProps],

    props: {
        subsidies: {
            type: Array,
            default() {
                return [];
            }
        }
    }
};
</script>

<style lang="less">
    .section--subsidies {
        .subsidy-card {
            @media @q-md-max {
                margin-top: .5rem;
            }

            @media @q-md-min {
                &:not(:last-of-type) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
</style>
