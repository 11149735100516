<template>
    <BaseSection v-if="hasImage">
        <ImageSingle
            v-if="image[0]"
            :image="image[0]"
            :caption="caption"
            :alt="alt"
        />
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    image: {
        type: Array,
        required: true,
        default: () => { return []; },
    },
    caption: {
        type: String,
        required: false,
        default: ''
    },
    alt: {
        type: String,
        required: false,
        default: ''
    }
});

const { image } = toRefs(props);
const hasImage = computed(() => image?.value?.[0]);
</script>
