<template>
    <BaseSection width="page-narrow" class="section--button" data-padding="small">
        <EntryLink
            v-if="link?.[0]"
            class="button button--primary"
            :class="colorClass"
            :link="link?.[0]"
        />
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    link: {
        type: Array,
        required: true,
        default() {
            return [];
        }
    },
    color: {
        type: String,
        default: 'primary'
    },
});


const colorClass = computed(() => {
    return `button--${props.color}`;
});
</script>

<style lang="less">
    .section--button {
        .button {
            .focus-ball-left();
        }
    }
</style>
